import {FaLinkedin , FaInstagram , FaGithub} from 'react-icons/fa'
import {FaSquareXTwitter} from 'react-icons/fa6'
import Logo from "../images/logo.png"
const Nav = () => {
  return (
    <nav className='mb-20 flex items-center justify-between py-6 '>
        <div className='flex flex-shrink-0 items-center'>
            <img className="mx-2 w-20 h-16" src={Logo}  alt="logo" /> {/* logo image*/}
        </div>
        <div className='m-8 flex items-center justify-center gap-4 text-2xl'>
            <FaLinkedin/>
            <FaInstagram/>
            <FaGithub/>
            <FaSquareXTwitter/>
        </div>
    </nav>
  )
}

export default Nav