
import Iqabbal from '../images/noir1.png'
import {motion} from 'framer-motion'

const container = (delay) =>(
    {
    hidden : {x : -100 ,opacity : 0},
    visible : {
        x : 0, 
        opacity : 1,
        transition : {
            duration : 0.5,
            delay : delay
        }
    },
})
const Hero = () => {
  return (
    <div className='border-b border-neutral-900 pb-4 lg:mb-35'>
        <div className='flex flex-wrap'>
                <div className='w-full lg:w-1/2'>
                    <div className='flex flex-col items-center  lg:items-start'>
                        <motion.h1 
                        variants={container(0)}
                        initial="hidden"
                        animate="visible"
                        className='pb-16 text-6xl font-thin tracking-tight lg:mt-16 lg:text-8xl'>ILYAS QABBAL</motion.h1>
                        <motion.span 
                        variants={container(0.5)}
                        initial="hidden"
                        animate="visible"
                        className='bg-gradient-to-r from-pink-300 via-slate-500 to-purple-500 bg-clip-text text-3xl tracking-tight text-transparent'>Full Stack Devloper</motion.span>
                        <motion.p 
                        variants={container(1)}
                        initial="hidden"
                        animate="visible"
                        className='my-2  max-w-xl py-6 font-light tracking-tighter'>Motivated and adaptable full-stack developer with a passion for learning and problem-solving. Eager to embark on an internship opportunity to apply and expand upon my technical skills while embracing new challenges in a dynamic environment. Strong foundation in both front-end and back-end development, coupled with a collaborative mindset and a drive for continuous improvement.</motion.p>
                    </div>
                </div>
                <div className='w-full lg:w-1/2 lg:p-8'>
                    <div className='flex justify-center items-center w-full'>
                        <motion.img initial={{x:100, opacity:0}} animate={{x:0, opacity:1}} transition={{duration:1,delay:1.2}} src={Iqabbal} alt="image ilyas" />
         
                    </div>
                </div>
        </div>
    </div>
  )
}

export default Hero