import About from "./components/About";
import Contact from "./components/Contact";
import Experience from "./components/Experience";
import Hero from "./components/Hero";
import Nav from "./components/Nav";
import Projects from "./components/Projects";
import Technologies from "./components/Technologies";


function App() {
  return (

    <div className='w-full h-full overflow-x-hidden text-neutral-300 antialiased selection:bg-cyan-300 selection:text-c'>
          <div className="fixed top-0 -z-10 h-full w-full">
            <div className="absolute top-0 z-[-2] h-screen w-screen bg-neutral-950 bg-[radial-gradient(ellipse_80%_80%_at_50%_-20%,rgba(120,119,198,0.3),rgba(255,255,255,0))]">
            </div>
          </div>
          <div className="container mx-auto px-8">
              <Nav/>
              <Hero/>
              <About/>
              <Technologies/>
              <Experience/>
              <Projects/>
              <Contact/>
          </div>
    </div>
  );
}

export default App;




/*

+---------------------+
|     User's Browser  |   <- Requests a web page from `localhost` (e.g., http://localhost:3000)
+---------------------+
           |
           | (1)
           |
           v
+---------------------+
|    Localhost DNS    |   <- Localhost resolves to 127.0.0.1 (local IP address)
+---------------------+
           |
           | (2)
           |
           v
+---------------------+
|  Local Web Server   |   <- Web server running locally (e.g., Node.js server)
| (e.g., Node.js)     |
+---------------------+
           |
           | (3)
           |
           v
+---------------------+
|    Server-Side Code |   <- Node.js processes the request, generates dynamic content if needed
|    (Node.js)        |
+---------------------+
           |
           | (4)
           |
           v
+---------------------+
|   HTML, CSS, JavaScript |   <- Web server sends these files to the browser
|    Files              |
+---------------------+
           |
           | (5)
           |
           v
+---------------------+
|     Browser         |   <- Receives and processes HTML, CSS, JavaScript files
+---------------------+
           |
           | (6)
           |
           v
+---------------------+
|   HTML Parser       |   <- Parses HTML into a DOM (Document Object Model) tree
|                     |
|   +-------------+   |
|   |   DOM Tree  |   |   <- Represents the structure and content of the web page
|   +-------------+   |
+---------------------+
           |
           | (7)
           |
           v
+---------------------+
|    CSS Parser       |   <- Parses CSS files and applies styles to the DOM elements
|                     |
|   +-------------+   |
|   |  CSSOM      |   |   <- CSS Object Model representing styles
|   +-------------+   |
+---------------------+
           |
           | (8)
           |
           v
+---------------------+
|   JavaScript Engine |   <- Executes JavaScript code, modifying the DOM or CSSOM
|                     |
|   +-------------+   |
|   |  Execution  |   |
|   +-------------+   |
+---------------------+
           |
           | (9)
           |
           v
+---------------------+
|   Render Engine     |   <- Combines DOM and CSSOM to create a render tree
|                     |
|   +-------------+   |
|   | Render Tree |   |   <- Layout and painting of the web page
|   +-------------+   |
+---------------------+
           |
           | (10)
           |
           v
+---------------------+
|   Display Window    |   <- Renders and displays the final web page to the user
+---------------------+


*/