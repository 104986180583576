import {motion} from 'framer-motion'
import PingPong from "../images/7.png"
import Tinder from "../images/5.png"
import Ecommerce from "../images/3.png"
import TaskMnagement from "../images/8.png"
import IRC from "../images/4.png"
import MiniStore from "../images/6.png"
import MinimalConstruction from "../images/1.png"


import JobFinder from "../images/2.png"

const projects = [{
    title : "ft_transcendence",
    image : PingPong,
    description : "ft_transcendence is an interactive web application where people can play Ping Pong, make new friends, and chat with each other. The technologies and frameworks we used to create the app are: Next.js, Nest.js, PostgreSQL, Tallwind CSS.",
    technologies : ["NextJs","NestJs","Docker","PostgresSql"]
},
{
    title : "ft_irc",
    image : IRC,
    description : "Built an IRC server for real-time text-based chat, connecting users worldwide. It supports diverse chat rooms, follows the IRC protocol for seamless message flow, and accommodates both standalone apps and web interfaces. Inspired by IRC's legacy, my server fosters vibrant communication and collaboration across communities.",
    technologies : ["c++","OOP"]
},
{
    title : "tinder-clone",
    image : Tinder,
    description : "Developed a Tinder clone app featuring an interest-based matching algorithm, JWT and cookies authentication, and user profile management. Enabled user functionalities such as photo uploads via URL, gender identity selection, and interest specification. Focused on creating a dynamic and responsive user interface to enhance user experience.",
    technologies : ["Mongodb","ExpressJs","ReactJs","NodeJs"]
},
{
    title : "E-commerce application ",
    image : Ecommerce,
    description : "The E-commerce App is a full-fledged online marketplace built using the MERN stack, providing users with a seamless shopping experience. It features various payment methods, MongoDB indexing and pagination for efficient data handling, authentication with JWT for secure user access, animations with Framer Motion for an engaging user interface, responsive design for accessibility across devices, and data modeling with Mongoose for robust database management.",
    technologies : ["Mongodb","ExpressJs","ReactJs","NodeJs"]
},
{
    title : "Task-management-system  application ",
    image : TaskMnagement,
    description : "This app is designed to help users organize, prioritize, and track their tasks efficiently. Built using the MERN stack, it features authentication with JWT and bcrypt for secure password management, real-time status updates, CORS configuration for seamless API communication, CRUD operations for managing tasks, MongoDB indexing for optimized performance, and a responsive design for usability across devices.",
    technologies : ["Mongodb","ExpressJs","ReactJs","NodeJs"]
}
]

const landingpages = [
    {
        title : "mini store landing page ",
        image : MiniStore,
        description : "https://mini-store-gamma.vercel.app/",
        technologies : ["ReactJs"]
    },
    {
        title : "minimal constructions  landing page",
        image : MinimalConstruction,
        description : "https://minimal-construction.vercel.app",
        technologies : ["ReactJs"]
    },
    {
        title : "job-finder  landing page",
        image : JobFinder,
        description : "https://job-finder-nine-rose.vercel.app",
        technologies : ["ReactJs"]
    },
]
//
//https://minimal-construction.vercel.app/
//https://mini-store-gamma.vercel.app/
//https://job-finder-nine-rose.vercel.app/
const Projects = () => {
  return (
    <div className='border-b border-neutral-900 pb-4'>
        <motion.h2 
        whileInView={{opacity:1, y:0}}  
        initial={{opacity:0, y:-100}} 
        transition={{duration:0.5}} 
        className='my-20  text-center text-4xl'>Projects</motion.h2>
        {projects.map((project , index)=>{
            return (
            <div key= {index} className='mb-8 flex flex-wrap lg:justify-center '> 
                <motion.div
                whileInView={{opacity:1, x:0}}  
                initial={{opacity:0, x:-100}} 
                transition={{duration:1}} 
                className='w-full lg:w-1/4'>
                    <img src={project.image} alt={project.title} width={150} height={150} className=' bg-red-300 mb-6 rounded'/>
                </motion.div>
                <motion.div 
                whileInView={{opacity:1, x:0}}  
                initial={{opacity:0, x:100}} 
                transition={{duration:1}} 

                className='w-full max-w-xl lg:w-3/4'>
                    <h6 className='mb-2 font-semibold'>{project.title}</h6>
                    <p className='mb-4 text-neutral-400'>{project.description}</p>
                    {project.technologies.map((technology , index)=>{
                                    return <span key={index} className='mr-2 mt-4 rounded bg-neutral-900 px-2 py-1 text-sm font-medium text-purple-800'>{technology}</span>
                            })}
                </motion.div>
            </div>)
        })}
        {landingpages.map((project , index)=>{
            return (
            <div key= {index} className='mb-8 flex flex-wrap lg:justify-center '> 
                <motion.div
                whileInView={{opacity:1, x:0}}  
                initial={{opacity:0, x:-100}} 
                transition={{duration:1}} 
                className='w-full lg:w-1/4'>
                    <img src={project.image} alt={project.title} width={150} height={150} className=' bg-red-300 mb-6 rounded'/>
                </motion.div>
                <motion.div 
                whileInView={{opacity:1, x:0}}  
                initial={{opacity:0, x:100}} 
                transition={{duration:1}} 

                className='w-full max-w-xl lg:w-3/4'>
                    <h6 className='mb-2 font-semibold'>{project.title}</h6>
                    <a href={project.description} className='mb-4 text-blue-400'>{project.description}</a><br/>
                    {project.technologies.map((technology , index)=>{
                                    return <span key={index} className='mr-2 mt-4 rounded bg-neutral-900 px-2 py-1 text-sm font-medium text-purple-800'>{technology}</span>
                            })}
                </motion.div>
            </div>)
        })}
    </div>
  )
}

export default Projects