// import AboutImg from '../assest/'
import {motion} from 'framer-motion'
import Dell from '../images/dell1.png'

const About = () => {
  return (
    <div className='border-b border-neutral-900 pb-4'>
        <h2 className='my-20 text-center text-4xl'>About <span className='text-neutral-500'>Me</span></h2>
        <div className='flex flex-wrap'>
            <motion.div
            whileInView={{opacity:1 , x:0}}
            initial={{opacity:0 , x:-100}}
            transition={{duration: 0.5}}
            className='w-full lg:w-1/2 lg:p-8'>
                <div className='flex items-center justify-center'>
                    <img  className="rounded-2xl" src={Dell} alt="About image" />
                </div>
            </motion.div>
            <motion.div 
            whileInView={{opacity:1 , x:0}}
            initial={{opacity:0 , x:100}}
            transition={{duration: 0.5}}
            className='w-full lg:w-1/2'>
                <div className='flex justify-center lg:justify-start'>
                    <p className='my-2 max-w-xl py-6'>Hello! I’m Ilyas Qabbal, a 22-year-old Software Engineering student at 1337, where I’ve completed the Common Core curriculum. My academic journey has equipped me with a solid foundation in software development, and I’m eager to apply my skills in a practical setting. With a keen interest in full-stack development, I am currently seeking an internship to gain hands-on experience, contribute to innovative projects, and further my growth as a developer. I’m passionate about problem-solving and excited to bring my enthusiasm and technical skills to a dynamic team.</p>
                </div>
            </motion.div>
        </div>
    </div>
  )
}

export default About