import {motion} from 'framer-motion'

const experiences = [{
    year : "2023 - Present",
    rol : "Freelance MERN Stack Developer",
    company : "Upwork , Fiverr",
    description : "As a freelance MERN Stack Developer, I build and maintain robust web applications using MongoDB, Express.js, React, and Node.js. I handle all phases of development, from initial concept through to deployment and maintenance, ensuring high performance and scalability. My role involves collaborating with clients to understand their requirements, crafting efficient solutions, and delivering reliable and user-friendly applications."
    ,technologies : ["ReactJs","NodeJs","ExpressJs","MongoDb"]
} ,
]
const Experience = () => {
  return (
    <div className='border-b border-neutral-900 pb-4'>
        <motion.h2 
        whileInView={{opacity:1, y:0}}  
        initial={{opacity:0, y:-100}} 
        transition={{duration:1}} 
        className='my-20 text-center text-4xl'>
            Experience 
        </motion.h2>
        <div>
            {
                experiences.map((experience,index)=>{
                    return (
                    <div key={index} className='mb-8 flex flex-wrap lg:justify-center'>
                        <motion.div 
                         whileInView={{opacity:1, x:0}}  
                         initial={{opacity:0, x:-100}} 
                         transition={{duration:1}} 
                        className='w-full lg:w-1/4'>
                            <p className='mb-2 text-sm text-neutral-400'>{experience.year}</p>
                        </motion.div>
                        <motion.div 
                        whileInView={{opacity:1, x:0}}  
                        initial={{opacity:0, x:100}} 
                        transition={{duration:1}} 

                        className="w-full max-w-xl lg:w-3/4">
                            <h6 className='mb-2 font-semibold'>{experience.rol} - <span className='text-sm text-purple-100'>{experience.company}</span> </h6>
                            <p className='mb-4 text-neutral-400'>{experience.description}</p>
                            {experience.technologies.map((technology , index)=>{
                                    return <span key={index} className='mr-2 mt-4 rounded bg-neutral-900 px-2 text-sm font-medium text-purple-800'>{technology}</span>
                            })}
                        </motion.div>
                    </div>
                    )
                })
            }
        </div>
    </div>
  )
}

export default Experience