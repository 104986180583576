import {motion} from 'framer-motion'

const contact = {
    address : "Khouribga Morocco",
    email : "qabbalilyas@gmail.com",
    phone : "06 52 75 83 24",
}
const Contact = () => {
  return (
    <div className="border-b border-neutral-900 pb-20">
        <motion.h2 
        whileInView={{opacity:1, y:0}}  
        initial={{opacity:0, y:-100}} 
        transition={{duration:0.5}} 
        className="my-10 text-center text-4xl">
            Get in Touch
        </motion.h2>
        <div className="text-center tracking-tighter">
            <motion.p 
             whileInView={{opacity:1, x:0}}  
             initial={{opacity:0, x:-100}} 
             transition={{duration:1}}
            className="my-4 ">
                {contact.address}
            </motion.p>
            <motion.p 
             whileInView={{opacity:1, x:0}}  
             initial={{opacity:0, x:100}} 
             transition={{duration:1}}
            className="my-4 ">
                {contact.phone}
            </motion.p>
            <a 
             className="my-4 border-b ">
                {contact.email}
            </a>
        </div>
    </div>
  )
}

export default Contact